import React, { FunctionComponent, useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import Button from "../components/Button"

import { Blog } from "../types"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Modal = ({ setShowForm, file }) => {
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [thankYouMessage, setThankYouMessage] = useState("")

  const handleSubmit = async event => {
    event.preventDefault() // Prevent the form from redirecting

    const formData = new FormData(event.target) // Get form data

    // Submit the form data via fetch to Mailchimp
    const response = await fetch(
      "https://mooremomentum.us20.list-manage.com/subscribe/post",
      {
        method: "POST",
        body: formData,
        mode: "no-cors", // Mailchimp might require this to avoid CORS issues
      }
    )

    // After successful submission, trigger the file download
    if (response.ok || response.type === "opaque") {
      // Handle no-cors responses or successful submissions
      const link = document.createElement("a")
      link.href = `${file}` // Path to the file
      link.download = `${file}`.replace("/", "") // File name for the download
      link.click()

      setThankYouMessage(
        `Thank you for your submission, ${firstName}! Your file is downloading now.`
      )
    } else {
      console.error("Error submitting form")
    }
  }
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            {thankYouMessage ? (
              <div className="pb-10">
                <h3 className="py-10 px-6 font-Orbitron text-center">
                  {thankYouMessage}{" "}
                </h3>
                <button
                  className="btns m-auto flex"
                  onClick={() => {
                    setThankYouMessage("")
                    setShowForm(false)
                  }}
                >
                  Close
                </button>
              </div>
            ) : (
              <>
                <h2 className="py-10 px-6 font-Orbitron text-center">
                  Download a PDF of Habit Stacking Worksheet
                </h2>
                <form
                  action="https://mooremomentum.us20.list-manage.com/subscribe/post"
                  method="POST"
                  className="pb-10 px-6"
                  onSubmit={handleSubmit} // Attach the submit handler
                >
                  <input
                    type="hidden"
                    name="u"
                    value="705d046da14d7bc6e462e6ec4"
                  />
                  <input type="hidden" name="id" value="fc1be8fe04" />
                  <input type="hidden" name="tags" value="2633292" />
                  {/* Hidden input for quiz tag */}

                  <div className="mb-8 space-y-8">
                    <div className="gap-4 grid sm:grid-cols-2">
                      <div>
                        <input
                          className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                          id="MERGE1"
                          name="MERGE1"
                          placeholder="First Name"
                          required
                          type="text"
                          value={firstName}
                          onChange={e => setFirstName(e.currentTarget.value)}
                        />
                      </div>

                      <div>
                        <input
                          className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                          id="MERGE2"
                          name="MERGE2"
                          placeholder="Last Name"
                          required
                          type="text"
                          value={lastName}
                          onChange={e => setLastName(e.currentTarget.value)}
                        />
                      </div>
                    </div>

                    <div>
                      <input
                        className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                        id="MERGE0"
                        name="MERGE0"
                        placeholder="Email Address"
                        required
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.currentTarget.value)}
                      />
                    </div>
                  </div>

                  <Button
                    className="text-sm md:text-base m-auto flex"
                    type="submit"
                  >
                    Get Access
                  </Button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

interface Props {
  data: {
    contentfulBlog: Blog
  }
}

const BlogTemplate: FunctionComponent<Props> = ({ data, location }) => {
  const [showForm, setShowForm] = useState(false)
  function formatDate(date: Date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }

  console.log(data.contentfulBlog.coverImage.url)
  return (
    <Layout>
      <SEO
        description={data.contentfulBlog.metaDescription}
        title={data.contentfulBlog.title}
        image={data.contentfulBlog.coverImage.url}
        link={`https://mooremomentum.com/blog/${data.contentfulBlog.slug}/`}
      />

      <div className="blog max-w-6xl mt-8 mx-auto px-4 blog_text">
        <figure className="mb-8 flex  justify-center rounded-lg">
          <GatsbyImage
            image={data.contentfulBlog.coverImage.gatsbyImageData}
            alt={data.contentfulBlog.metaDescription}
            className="rounded-lg w-50"
            style={{ width: "70%", height: "70%" }}
            loading="eager"
          />
        </figure>

        <h1 className="font-Orbitron mb-4 text-center text-2xl sm:text-3xl lg:text-4xl">
          {data.contentfulBlog.title}
        </h1>

        <h4 className="text-center">
          <b>{formatDate(new Date(data.contentfulBlog.datePublished))}</b>
        </h4>
        <h4 className="text-center">
          <b>By Will Moore</b>
        </h4>

        <div className="py-8 space-y-4 blogitem">
          {/* @ts-ignore */}
          {renderRichText(data.contentfulBlog.content, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2
                  className="font-Orbitron text-xl"
                  id={
                    children[0]
                      ? children[0].props?.children
                          .replaceAll(" ", "-")
                          .replaceAll(/\u00a0/g, "-")
                      : children
                          ?.replaceAll(" ", "-")
                          .replaceAll(/\u00a0/g, "-")
                  }
                >
                  {children}
                </h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <>
                  {" "}
                  {console.log(children)}
                  <h3
                    className="font-Orbitron text-lg"
                    id={
                      children[0]
                        ? children[0].props?.children
                            .replaceAll(" ", "-")
                            .replaceAll(/\u00a0/g, "-")
                        : children
                        ? children[0]
                            .replaceAll(" ", "-")
                            .replaceAll(/\u00a0/g, "-")
                        : children
                            .replaceAll(" ", "-")
                            .replaceAll(/\u00a0/g, "-")
                    }
                  >
                    {children}
                  </h3>
                </>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    {node.data.target.file.fileName ===
                    "button press start to begin BLACK VERSION (1) (1).gif" ? (
                      <a href="/core-values-quiz/" target="_blank">
                        <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                      </a>
                    ) : node.data.target.file.fileName === "contact.gif" ? (
                      <a href="/life-coach-for-young-adults/" target="_blank">
                        <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                      </a>
                    ) : (
                      <GatsbyImage
                        image={gatsbyImageData}
                        alt="Blog Image"
                        style={{ maxWidth: "750px" }}
                        objectFit="contain"
                      />
                    )}
                  </figure>
                )
              },
            },
          })}
        </div>
        {data.contentfulBlog?.fileText && (
          <div className="file" onClick={() => setShowForm(!showForm)}>
            <span> {data.contentfulBlog?.fileText} </span>
          </div>
        )}
        {data.contentfulBlog.newContent && (
          <div className="py-8 space-y-4 blogitem">
            {/* @ts-ignore */}
            {renderRichText(data.contentfulBlog.newContent, {
              renderMark: {
                [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
                [MARKS.ITALIC]: text => <em>{text}</em>,
              },
              renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                [BLOCKS.HEADING_1]: (node, children) => (
                  <h1 className="font-Orbitron text-2xl">{children}</h1>
                ),
                [BLOCKS.HEADING_2]: (node, children) => (
                  <h2
                    className="font-Orbitron text-xl"
                    id={
                      children[0]
                        ? children[0].props?.children
                            .replaceAll(" ", "-")
                            .replaceAll(/\u00a0/g, "-")
                        : children
                            ?.replaceAll(" ", "-")
                            .replaceAll(/\u00a0/g, "-")
                    }
                  >
                    {children}
                  </h2>
                ),
                [BLOCKS.HEADING_3]: (node, children) => (
                  <>
                    {" "}
                    {console.log(children)}
                    <h3
                      className="font-Orbitron text-lg"
                      id={
                        children[0]
                          ? children[0].props?.children
                              .replaceAll(" ", "-")
                              .replaceAll(/\u00a0/g, "-")
                          : children
                          ? children[0]
                              .replaceAll(" ", "-")
                              .replaceAll(/\u00a0/g, "-")
                          : children
                              .replaceAll(" ", "-")
                              .replaceAll(/\u00a0/g, "-")
                      }
                    >
                      {children}
                    </h3>
                  </>
                ),
                [BLOCKS.EMBEDDED_ASSET]: node => {
                  if (!node.data.target) {
                    return
                  }
                  // console.log("Imagess",node.data.target.gatsbyImageData)
                  const { gatsbyImageData } = node.data.target
                  if (!gatsbyImageData) {
                    // asset is not an image
                    return null
                  }
                  return (
                    <figure className="flex flex-wrap justify-center items-center">
                      {node.data.target.file.fileName ===
                      "button press start to begin BLACK VERSION (1) (1).gif" ? (
                        <a href="/core-values-quiz/" target="_blank">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </a>
                      ) : node.data.target.file.fileName === "contact.gif" ? (
                        <a href="/life-coach-for-young-adults/" target="_blank">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </a>
                      ) : (
                        <GatsbyImage
                          image={gatsbyImageData}
                          alt="Blog Image"
                          style={{ maxWidth: "750px" }}
                          objectFit="contain"
                        />
                      )}
                    </figure>
                  )
                },
              },
            })}
          </div>
        )}
        {showForm && (
          <Modal setShowForm={setShowForm} file={data.contentfulBlog?.file} />
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
            file {
              fileName
            }
          }
        }
      }
      newContent {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
            file {
              fileName
            }
          }
        }
      }
      file
      fileText
      datePublished
      id
      metaDescription
      slug
      title
      coverImage {
        url
        gatsbyImageData(layout: CONSTRAINED)
        file {
          fileName
        }
      }
    }
  }
`

export default BlogTemplate
